import { useState, useEffect, useRef, Fragment } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import _ from 'underscore';

import { Container, PageHeading, Heading } from "./Components";

import { MapContainer, TileLayer, FeatureGroup, Marker, Popup, CircleMarker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'

import config from "../config";
import { isVisible } from "@testing-library/user-event/dist/utils";

function SagaMapView(props) {
	const [mapData, setMapData] = useState(null);
	const [locationsIndex, setLocationsIndex] = useState({});
	const [sagaData, setSagaData] = useState(null);
	const [markerType, setMarkerType] = useState('circles');
	const [visibleMarkers, setVisibleMarkers] = useState([]);

	const navigate = useNavigate();
	const location = useLocation();


	const map = useRef();
	const sagaTextEl = useRef();
	const sagaTextContainer = useRef();
	const markerRefs = useRef({});

	let { sagaId, chapterNumber } = useParams();

	const apiRoot = 'https://sagamap.hi.is/data/data.php';

	const scrollToChapter = (chapter) => {
		//document.getElementById('chapter-'+chapter).scrollIntoView();

		const yOffset = -130; 
		const element = document.getElementById('chapter-'+chapter);
		const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
		
		window.scrollTo({top: y});
	}

	useEffect(() => {
		if (document.getElementById('chapter-'+chapterNumber)) {
			scrollToChapter(chapterNumber);

			setTimeout(() => {
				checkVisibleSagaMarkers()
			}, 500);
		}
	}, [chapterNumber]);

	useEffect(() => {
		fetch(apiRoot+'?a=getsagaplaces&saga='+sagaId)
			.then(res => res.json())
			.then(json => {
				setMapData(json);

				let index = {};

				json.places.forEach(p => {
					index[p.id] = {
						lat: p.coordinates[0],
						lng: p.coordinates[1],
						name: p.name
					};
				})

				setLocationsIndex(index);
			});

		fetch(apiRoot+'?a=getsaga_v2&saga='+sagaId)
			.then(res => res.json())
			.then(json => {
				console.log('saga data load')
				setSagaData(json);

				if (chapterNumber) {
					console.log('go now to chapter '+chapterNumber)

					setTimeout(() => {
						scrollToChapter(chapterNumber);
					}, 500);
				}
			});

	}, [sagaId]);

	const windowScrollHandler = event => {
		if (!window.scrollTimeout) {
			window.scrollTimeout = setTimeout(() => {
				checkVisibleSagaMarkers();

				window.scrollTimeout = false;
			}, 200)
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', windowScrollHandler)

		return () => {
			window.removeEventListener('scroll', windowScrollHandler);
		}
	}, []);

	const iconResize = 0.6;

	const defaultIcon = new L.icon({
		iconAnchor: [12*iconResize, 41*iconResize],
		iconRetinaUrl: '/img/marker-icon-2x.png',
		iconSize: [25*iconResize, 41*iconResize],
		iconUrl: '/img/marker-icon.png',
		popupAnchor: [1*iconResize, -34*iconResize],
		shadowSize: [41*iconResize, 41*iconResize],
		shadowUrl: '/img/marker-shadow.png',
		tooltipAnchor: [16*iconResize, -28*iconResize]
	});

	const circleMarkerOptions = {
		color: '#000000',
		fillColor: '#ffffff',
		fillOpacity: 0.7,
		weight: 1
	};

	const sagaTextClick = (event) => {
		if (event.target.tagName.toLowerCase() == 'span') {
			if (event.target.getAttribute('geo-ref')) {
				let geoRef = event.target.getAttribute('geo-ref');

				markerRefs.current[geoRef].openPopup();
			}
		}
	};

	const checkVisibleSagaMarkers = () => {
		const container = sagaTextContainer.current;

		let _visibleMarkers = [];
		let visibleBounds = [];
	
		for (let span of sagaTextEl.current.getElementsByTagName('span')) {
			
			if (span.getAttribute('geo-ref')) {
				const rect = span.getBoundingClientRect();

				let isVisible = (
					rect.top >= 0 &&
					rect.left >= 0 &&
					rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
					rect.right <= (window.innerWidth || document.documentElement.clientWidth)
				);
						
				if (isVisible) {
					let geoRef = span.getAttribute('geo-ref');
					_visibleMarkers.push(geoRef);

					if (markerRefs.current && markerRefs.current[geoRef]) {
						visibleBounds.push(markerRefs.current[geoRef].getLatLng());

						//markerRefs.current[geoRef].openTooltip();
					}
					else {
						//markerRefs.current[geoRef].closeTooltip();
					}
				}
			}
		}

		if (visibleBounds.length > 0) {
			map.current.closePopup();
			map.current.fitBounds(visibleBounds, {
				maxZoom: 8
			});
		}

		setVisibleMarkers(_visibleMarkers);
	}

	return <div>
		{
			(sagaData || mapData) && <>
				{
					sagaData && <Container className={'min-h-[230px]'}>
						<PageHeading>{sagaData.name}</PageHeading>

						<p dangerouslySetInnerHTML={{__html: sagaData.info }} />

					</Container>
				}


				<Container wide={true} className="relative lg:z-10">
					<div className="flex gap-10">

						<div className="w-1/2 lg:w-2/3 sticky top-4 self-start" style={{
							height: 'calc(100vh - 2rem)'
						}}>
							<MapContainer ref={map} 
								center={[64.963051, -19.020836]} 
								minZoom={4} 
								maxZoom={12} 
								zoom={6} 
								scrollWheelZoom={true}
								className="!h-full"
							>
								<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url="https://nafnid.is/gis/island-tiles/{z}/{x}/{y}.png"
								/>
								<TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
									attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
								/>
								<FeatureGroup>
									{
										mapData && mapData.places.map((item, index) => markerType == 'markers' ? <Marker key={index}
											icon={defaultIcon}
											title={item.name}
											position={item.coordinates}
											ref={(m) => {
												markerRefs.current[item.id] = m;
											}}
										>
											<Popup open={true}><div className="text-sm font-bold">{item.name}</div></Popup>
										</Marker> : <CircleMarker key={index}
											title={item.name}
											center={item.coordinates}
											pathOptions={circleMarkerOptions}
											radius={visibleMarkers.indexOf(item.id) > -1 ? 14 : 4}
											ref={(m) => {
												markerRefs.current[item.id] = m;
											}}
										>
											{
												visibleMarkers.indexOf(item.id) > -1 && <Tooltip permanent>{item.name}</Tooltip>
											}
											<Popup open={true}>
												<div className="text-lg font-bold">{item.name}</div>
												<div>
													{
														Object.keys(item.sagas).map((key) => {
															return <div key={key}><span className="font-bold">{item.sagas[key].title}</span>: {
																item.sagas[key].chapters.map((chapter, chapterIndex) => <Link key={chapterIndex} to={'/saga/'+item.sagas[key].id+'/'+chapter.chapter}>{chapterIndex > 0 ? ', ' : ''}<span>{chapter.title}</span></Link>)
															}</div>
														})
													}
												</div>
											</Popup>
										</CircleMarker>)
									}
								</FeatureGroup>
							</MapContainer>
						</div>

						<div className="w-1/2 lg:w-1/3 relative">
						
							{
								sagaData && <>


									<div className="sticky top-0 pt-4 pb-6 bg-[#f9f8f1] border-b border-b-gray">
									<h2 className="">{sagaData.name}</h2>

										<select className="shadow-lg w-full" value={chapterNumber || 1} onChange={(event) => {
											navigate('/saga/'+sagaId+(event.target.value ? '/'+event.target.value : ''))
										}}>
											<option value={1}>Chapter</option>
											{
												sagaData && sagaData.chapters.map((chapter, index) => <option key={index} value={chapter.number}>{chapter.title}</option>)
											}
										</select>

									</div>

									<div ref={sagaTextContainer} className="saga-text-container font-lora overflow-hidden">
										<div ref={sagaTextEl} dangerouslySetInnerHTML={{__html: sagaData.text}} onClick={sagaTextClick} />
									</div>

								</>
							}

						</div>

					</div>


				</Container>

			</>
		}
	</div>
}

export default SagaMapView;